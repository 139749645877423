// src/scripts.ts
export function injectAnalyticsScripts(
  analyticsId: string,
  gtmId: string
): void {
  // Inject Google Analytics (GA)
  const gaScript = document.createElement('script');
  gaScript.async = true;
  gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
  document.head.appendChild(gaScript);

  const gaInlineScript = document.createElement('script');
  gaInlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${analyticsId}');
    `;
  document.head.appendChild(gaInlineScript);

  // Inject Google Tag Manager (GTM)
  const gtmScript = document.createElement('script');
  gtmScript.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${gtmId}');
    `;
  document.head.appendChild(gtmScript);

  // Inject GTM <noscript> fallback
  const gtmNoscript = document.createElement('noscript');
  gtmNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
              height="0" width="0" style="display: none; visibility: hidden;"></iframe>
    `;
  document.body.appendChild(gtmNoscript);
}
