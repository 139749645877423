<div *ngIf="loading$ | async" class="loading-spinner">
  <div>
    <img
      src="../../assets/loading.gif"
      alt="loading"
      width="100px"
      height="100px"
    />
  </div>
  <div class="py-3 text-white">Finding Best Deals ...</div>
</div>
