import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { Directive, ElementRef } from '@angular/core';
import { environment } from './environments/environment';
import { injectAnalyticsScripts } from './scripts';

// Dynamically inject analytics scripts based on the environment
if (environment.enableAnalytics) {
  injectAnalyticsScripts('G-F1R1CC6JDQ', 'GTM-NRXJHCBT');
} else {
  console.log('Analytics disabled for this environment.');
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes), // if you have routing
    provideAnimations(),
    provideHttpClient(withFetch()),
    // other providers
  ],
}).catch((err) => console.error(err));

@Directive({ selector: 'img' })
export class LazyImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }
  }
}
