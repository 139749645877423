import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  TransferState,
  ViewChild,
  makeStateKey,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { LoadingService } from '../loading/loading.service';
import { CartService } from '../../services/cart.service';
import { AuthService } from '../auth-service.service';
import { UsersService } from '../../services/user.service';
import { WishlistService } from '../../services/wishlist.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ToastService } from '../shared/toast.service';
// import { , make } from '@angular/platform-browser';

const NEW_ARRIVALS_KEY = makeStateKey<any[]>('newArrivals');

@Component({
  selector: 'app-productlist-horizontal',
  templateUrl: './productlist-horizontal.component.html',
  styleUrls: ['./productlist-horizontal.component.scss'],
  imports: [CommonModule, FormsModule],
  standalone: true,
})
export class ProductListHorizontalComponent implements OnInit {
  @ViewChild('pinCodeModal') pinCodeModal: any;
  newArrivals: any[] = [];
  wishlistIcon = '/assets/fav.png';
  products: any[] = [];
  userDTO: any;
  isSeller: any;
  cart: any;
  wishlistItems: any;
  starsArray = [1, 2, 3, 4, 5];
  filteredProducts: any[] = [];
  brandFilter: string[] = [];
  ratingFilter: number[] = [];
  priceRangeFilter: any[] = [];
  subCatFilter: any[] = [];
  priceRanges: any[] = [];
  uinqueBrandSet: any[] = [];
  uniqueSubCatSet: any[] = [];
  sortOrder: 'asc' | 'desc' = 'asc'; // Default sort order
  sortOption: boolean = false;
  paginatedProducts: any[] = []; // Products displayed on current page
  itemsPerPage = 10;
  currentPage = 1;
  totalPages = 1;
  pageArray: any[] = [1];
  showFullPagination = false;
  pinCode: any = '800001';
  sortType: string = 'relevancy';

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router,
    private cartService: CartService,
    private authService: AuthService,
    private userService: UsersService,
    private wishlistService: WishlistService,
    private localStorage: LocalStorageService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  header: any;

  ngOnInit(): void {
    this.loadingService.show();
    if (this.localStorage.getItem('pinCode')) {
      this.pinCode = this.localStorage.getItem('pinCode');
    } else {
      this.pinCode = '800001';
    }
    // Access resolved data
    this.route.data.subscribe((data) => {
      if (this.route.snapshot.routeConfig?.path == 'newproducts') {
        this.header = 'New Products';
        this.products = data['newArrivals'];
        this.title.setTitle(
          'New Products | Power Bihar - Explore the Latest Havells Room Heaters & Seasonal Essentials'
        );
        // this.meta.updateTag({ property: 'og:url', content: window.location.href });
        this.meta.updateTag({
          property: 'og:title',
          content:
            'New Products | Power Bihar - Explore the Latest Havells Room Heaters & Seasonal Essentials',
        });
        this.meta.updateTag({
          property: 'og:description',
          content:
            "Discover the newest additions to Power Bihar's inventory! This winter, shop the latest Havells room heaters for cozy warmth and comfort. Stay updated with seasonal electrical essentials and premium products for your home and business in Bihar. Explore now for quality you can trust.",
        });
        this.meta.updateTag({
          name: 'keywords',
          content:
            'New Havells products, latest Havells room heaters, seasonal electrical essentials Bihar, winter electrical products Bihar, buy Havells products online, room heaters Bihar, Power Bihar new arrivals.',
        });
      } else if (this.route.snapshot.routeConfig?.path == 'bestseller') {
        this.header = 'Best Sellers';
        this.products = data['bestSellers'];
        this.title.setTitle(
          'Bestsellers | Power Bihar - Top Havells Products & Durga PVC Electrical Pipes'
        );
        // this.meta.updateTag({ property: 'og:url', content: window.location.href });
        this.meta.updateTag({
          property: 'og:title',
          content:
            'Bestsellers | Power Bihar - Top Havells Products & Durga PVC Electrical Pipes',
        });
        this.meta.updateTag({
          property: 'og:description',
          content:
            "Explore Power Bihar's best-selling products, featuring top Havells electrical appliances and high-quality Durga PVC electrical pipes. Trusted by customers across Bihar, these products are perfect for durable and reliable electrical solutions. Shop our bestsellers today for unbeatable quality and value.",
        });
        this.meta.updateTag({
          name: 'keywords',
          content:
            'Bestselling Havells products, Durga PVC electrical pipes, top electrical products Bihar, buy Havells appliances, durable electrical pipes Bihar, trusted electrical solutions, Power Bihar bestsellers.',
        });
      } else if (this.route.snapshot.routeConfig?.path == 'allproducts') {
        this.header = 'Our Products';
        this.products = data['allProducts'];
      } else if (
        this.route.snapshot.routeConfig?.path == 'category/:category'
      ) {
        this.header = this.route.snapshot.paramMap.get('category');
        this.products = data['allProducts'];
        this.title.setTitle(
          this.header +
            ' | Power Bihar - Explore ' +
            this.header +
            ' at best price in Bihar'
        );
        // this.meta.updateTag({ property: 'og:url', content: window.location.href });
        this.meta.updateTag({
          property: 'og:title',
          content:
            this.header +
            ' | Power Bihar - Explore ' +
            this.header +
            ' at best price in Bihar',
        });
      } else if (
        this.route.snapshot.routeConfig?.path == 'subcategory/:subcategory'
      ) {
        // this.header = this.route.snapshot.paramMap.get('subcategory');
        this.products = data['allProducts'];
        if (this.products.length > 0) {
          this.header = this.products[0].subCategory;
        }
        const queryParams = {
          name: this.header,
          // price: this.product.finalAmtWithTax,
        };
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // 'merge' retains existing query params
        });
        this.title.setTitle(
          this.header +
            ' | Power Bihar - Explore ' +
            this.header +
            ' at best price in Bihar'
        );
        // this.meta.updateTag({ property: 'og:url', content: window.location.href });
        this.meta.updateTag({
          property: 'og:title',
          content:
            this.header +
            ' | Power Bihar - Explore ' +
            this.header +
            ' at best price in Bihar',
        });
      } else if (this.route.snapshot.routeConfig?.path == 'brand/:brandId') {
        this.products = data['allProducts'];
        if (this.products.length > 0) {
          this.header = this.products[0].brandInfo.brandName;
        }
        const queryParams = {
          name: this.header,
          // price: this.product.finalAmtWithTax,
        };
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // 'merge' retains existing query params
        });
        this.title.setTitle(
          this.header +
            ' | Power Bihar - Explore ' +
            this.header +
            ' products at best price in Bihar'
        );
        // this.meta.updateTag({ property: 'og:url', content: window.location.href });
        this.meta.updateTag({
          property: 'og:title',
          content:
            this.header +
            ' | Power Bihar - Explore ' +
            this.header +
            ' products at best price in Bihar',
        });
      }
      setTimeout(() => {
        this.loadingService.hide();
      }, 500);
      this.filteredProducts = [...this.products];
      this.generatePriceRanges();
      this.uniqueBrands();
      this.uniqueSubCat();

      this.applyFilters();
      this.sortProducts('relevancy');
    });
  }
  generatePriceRanges() {
    const allPrices = this.products.map((p) => p.finalAmtWithTax);
    let minPrice = Math.min(...allPrices);
    let maxPrice = Math.max(...allPrices);
    // Calculate range gap and round the values to the nearest 100
    let rangeGap = Math.max(Math.floor((maxPrice - minPrice) / 4), 500);
    if (maxPrice === minPrice) {
      maxPrice += 600;
    }
    this.priceRanges = [
      {
        min: Math.floor(minPrice / 100) * 100,
        max: Math.floor((minPrice + rangeGap) / 100) * 100 - 1,
      },
      {
        min: Math.floor((minPrice + 1 * rangeGap) / 100) * 100,
        max: Math.floor((minPrice + 2 * rangeGap) / 100) * 100 - 1,
      },
      {
        min: Math.floor((minPrice + 2 * rangeGap) / 100) * 100,
        max: Math.floor((minPrice + 3 * rangeGap) / 100) * 100 - 1,
      },
      {
        min: Math.floor((minPrice + 3 * rangeGap) / 100) * 100,
        max: Math.floor((minPrice + 5 * rangeGap) / 100) * 100 - 1,
      },
    ];
  }
  sortProductsByPrice() {
    this.paginatedProducts.sort((a, b) => {
      if (this.sortOrder === 'asc') {
        return a.finalAmtWithTax - b.finalAmtWithTax;
      } else {
        return b.finalAmtWithTax - a.finalAmtWithTax;
      }
    });
  }
  // toggleSortOrder(sortOrder: any) {
  //   this.sortOrder = sortOrder;
  //   this.sortProductsByPrice(); // Call the sort function after toggling
  // }

  sortProducts(sortType: string): void {
    if (!this.paginatedProducts || this.paginatedProducts.length === 0) {
      return; // Handle empty or undefined product list
    }
    this.sortType = sortType;

    if (sortType === 'price') {
      this.paginatedProducts.sort((a, b) => {
        const priceA = a.finalAmtWithTax ?? 0;
        const priceB = b.finalAmtWithTax ?? 0;
        return this.sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
      });
    } else if (sortType === 'relevancy') {
      this.paginatedProducts.sort((a, b) => {
        const relevancyA = a.relevancy ?? Number.MAX_VALUE; // If relevancy is undefined, treat as least relevant
        const relevancyB = b.relevancy ?? Number.MAX_VALUE;
        const priceA = a.finalAmtWithTax ?? 0;
        const priceB = b.finalAmtWithTax ?? 0;

        // Sort by relevancy first, then price if relevancy is the same
        if (relevancyA === relevancyB) {
          return priceA - priceB; // Low to high price sorting
        }
        return relevancyA - relevancyB; // Low to high relevancy sorting
      });
    }
  }

  toggleSortOrder(
    sortOrder: 'asc' | 'desc',
    sortType: 'price' | 'relevancy'
  ): void {
    this.sortOrder = sortOrder;
    this.sortProducts(sortType);
  }

  // Close the dropdown if the user clicks outside the dropdown
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const dropdownElement = document.querySelector('.sort-dropdown');
    const buttonElement = document.querySelector('.bgorange');

    // Check if the click was outside both the dropdown and the button
    if (
      dropdownElement &&
      !dropdownElement.contains(event.target as Node) &&
      buttonElement &&
      !buttonElement.contains(event.target as Node)
    ) {
      this.sortOption = false;
    }
  }

  uniqueBrands() {
    const brandSet = new Set(
      this.products.map((product) => product.brandInfo.brandName)
    );
    this.uinqueBrandSet = Array.from(brandSet);
  }
  uniqueSubCat() {
    const subCatSet = new Set(
      this.products.map((product) => product.subCategory)
    );
    this.uniqueSubCatSet = Array.from(subCatSet);
  }

  // Apply filters
  applyFilters() {
    this.filteredProducts = this.products.filter((product) => {
      // Check if all conditions are satisfied
      const brandMatch =
        this.brandFilter.length === 0 ||
        this.brandFilter.includes(product.brandInfo.brandName);

      const ratingMatch =
        this.ratingFilter.length === 0 ||
        this.ratingFilter.includes(product.avgRatings.overallRating);

      const priceMatch =
        this.priceRangeFilter.length === 0 ||
        this.priceRangeFilter.some(
          (range) =>
            product.finalAmtWithTax >= range.min &&
            product.finalAmtWithTax <= range.max
        );

      const subCatMatch =
        this.subCatFilter.length === 0 ||
        this.subCatFilter.includes(product.subCategory);

      // Return true only if all conditions are met
      return brandMatch && ratingMatch && priceMatch && subCatMatch;
    });

    if (this.sortType === 'price') {
      this.filteredProducts = this.filteredProducts.sort((a, b) => {
        const priceA = a.finalAmtWithTax ?? 0;
        const priceB = b.finalAmtWithTax ?? 0;
        return this.sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
      });
    } else if (this.sortType === 'relevancy') {
      this.filteredProducts = this.filteredProducts.sort((a, b) => {
        const relevancyA = a.relevancy ?? Number.MAX_VALUE; // If relevancy is undefined, treat as least relevant
        const relevancyB = b.relevancy ?? Number.MAX_VALUE;
        const priceA = a.finalAmtWithTax ?? 0;
        const priceB = b.finalAmtWithTax ?? 0;

        // Sort by relevancy first, then price if relevancy is the same
        if (relevancyA === relevancyB) {
          return priceA - priceB; // Low to high price sorting
        }
        return relevancyA - relevancyB; // Low to high relevancy sorting
      });
    }
    this.currentPage = 1;
    this.calculateTotalPages();
    this.updatePaginatedProducts();
    this.updatePageArray();
  }
  updatePaginatedProducts() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedProducts = this.filteredProducts.slice(startIndex, endIndex);
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(
      this.filteredProducts.length / this.itemsPerPage
    );
    for (let i = 2; i <= this.totalPages; i++) {
      this.pageArray.push(i);
    }
  }
  // Pagination logic for displaying the page numbers
  updatePageArray() {
    if (this.totalPages <= 6 || this.showFullPagination) {
      // Show all pages if total is less than 6 or if full pagination is expanded
      this.pageArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      // Show limited page range with "..."
      const startPages = [1, 2, 3, 4];
      const endPages = [this.totalPages];
      this.pageArray = [...startPages, -1, ...endPages]; // -1 represents "..."
    }
  }
  // Pagination Controls
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedProducts();
    }
  }
  goToPage(page: any) {
    if (page !== -1) {
      // Ignore clicks on "..."
      this.currentPage = page;
      this.updatePaginatedProducts();
      this.updatePageArray();
    } else {
      this.expandPagination();
    }
  }
  expandPagination() {
    this.showFullPagination = true;
    this.updatePageArray();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedProducts();
    }
  }
  updateBrandFilter(brand: string) {
    if (this.brandFilter.includes(brand)) {
      this.brandFilter = this.brandFilter.filter((b) => b !== brand);
    } else {
      this.brandFilter.push(brand);
    }
    this.applyFilters();
  }
  updateSubCatFilter(subCat: string) {
    if (this.subCatFilter.includes(subCat)) {
      this.subCatFilter = this.subCatFilter.filter((b) => b !== subCat);
    } else {
      this.subCatFilter.push(subCat);
    }
    this.applyFilters();
  }

  updateRatingFilter(rating: number) {
    if (this.ratingFilter.includes(rating)) {
      this.ratingFilter = this.ratingFilter.filter((r) => r !== rating);
    } else {
      this.ratingFilter.push(rating);
    }
    this.applyFilters();
  }
  updatePriceRangeFilter(rangeIndex: number) {
    const range = this.priceRanges[rangeIndex];
    if (this.priceRangeFilter.includes(range)) {
      this.priceRangeFilter = this.priceRangeFilter.filter((r) => r !== range);
    } else {
      this.priceRangeFilter.push(range);
    }
    this.applyFilters();
  }

  getFloor(arg0: any) {
    return Math.floor(arg0);
  }
  toggleWishlist(product: any): void {
    product.wishlisted = !product.wishlisted;
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.wishlistService
        .addWishlist(product.productId, this.userDTO.id, product.listingId)
        .subscribe((data) => {});
    });
  }
  addToCart(product: any) {
    if (!this.isLoggedIn()) {
      this.router.navigate(['/signin']);
      return;
    }

    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;

      if (!this.hasCompleteUserDetails()) {
        this.showProfileIncompleteModal();
        return;
      }

      const addProductToCartDto = {
        price: product.finalAmtWithTax,
        product_color_id: '1',
        product_id: product.productId,
        quantity: product.lotSize,
        sellerId: product.sellerId,
        tax: product.taxAmount * product.lotSize,
        mrp: product.mrp,
        listingId: product.listingId,
        pinCode: this.pinCode,
      };

      if (product.isAvailableAtShippingAddress) {
        this.cartService.addCart(addProductToCartDto).subscribe(
          (data) => {
            this.cart = data;
            // alert('Product added to cart successfully.');
            this.toastService.showToast(
              'Product added to cart successfully!',
              'bg-success'
            );
            this.updateCartItemCount();
          },
          (error) => {
            console.error('Error adding product to cart:', error);
            this.toastService.showToast(
              'Failed to add product to cart.',
              'bg-danger'
            );
          }
        );
      } else {
        // alert('Item not available at Default Shipping Address');
        this.toastService.showToast(
          'Item not available at Default Shipping Address.',
          'bg-warning'
        );
      }
    });
  }
  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  navigateToProductDetails(productId: string, listingId: string) {
    this.router.navigate([`/product/${productId}/${listingId}`]);
  }
  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      // Calculate total items count in the cart
      const totalCount = cartList.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      // Update cart item count in the service
      this.cartService.updateCartItemCount(totalCount);
    });
  }
  user() {
    this.userService.getUser().subscribe((data) => {
      this.userDTO = data;
      this.isSeller = this.userDTO.isSeller || false;
    });
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.userDTO;
    return phoneNumber;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }
  addBusinessDays(date: Date, daysToAdd: number): Date {
    let businessDaysAdded = 0;
    let newDate = new Date(date); // Create a copy of the input date

    while (businessDaysAdded < daysToAdd) {
      newDate.setDate(newDate.getDate() + 1); // Move to the next day

      // Check if it's not Saturday (6) or Sunday (0)
      if (newDate.getDay() !== 6 && newDate.getDay() !== 0) {
        businessDaysAdded++;
      }
    }

    return newDate;
  }
  formatDate(days: any): String {
    const today = new Date();

    // Add 7 days
    const deliveryDate = new Date(today);
    deliveryDate.setDate(this.addBusinessDays(today, days).getDate());
    return deliveryDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  }
  resetAllFilters() {
    this.brandFilter = [];
    this.subCatFilter = [];
    this.priceRangeFilter = [];
    this.ratingFilter = [];
    this.applyFilters();
  }
  editPincode() {
    this.modalService.open(this.pinCodeModal);
  }
  setPinCode() {
    this.localStorage.setItem('pinCode', this.pinCode);
    // this.getProductDetails(this.productId);
    if (isPlatformBrowser(this.platformId)) {
      // Safe to use window functions in the browser
      window.location.reload();
    }
    this.modalService.dismissAll();
  }
}
