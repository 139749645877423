import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-order-tracking-seller',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-tracking-seller.component.html',
  styleUrl: './order-tracking-seller.component.scss',
})
export class OrderTrackingSellerComponent implements OnInit {
  identifier: string = '';
  orderTrackerData: any;
  orderItemDetails: any;
  statusSteps: any[] = [];
  firstProductImageUrl: string = '';
  showOrderDetails: boolean = false;
  deliveryDate: any;
  deliveredStatus: any;
  returnValidDate: any;
  returnStatus: any;
  returnedDate: any;
  returnApprovedStatus: any;
  returnApprovedDate: any;
  returnCompleteStatus: any;
  returnCompleteDate: any;
  toggleOrderDetails() {
    this.showOrderDetails = !this.showOrderDetails;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.identifier = params['orderIdentifier']; // Ensure 'orderIdentifier' is in your route
      if (this.identifier) {
        this.getOrderTracker(this.identifier);
      }
    });
  }
  goToOrderList() {
    this.router.navigate(['/sellerorder']);
  }

  getOrderTracker(orderIdentifier: string) {
    this.orderService.getOrderTracker(orderIdentifier).subscribe(
      (data) => {
        this.orderTrackerData = data;

        // if (this.orderTrackerData?.productImage?.length > 0) {
        //   this.firstProductImageUrl =
        //     this.orderTrackerData.productImage[0].imagePublicUrl; // Get image URL from `imagePublicUrl`
        // }

        if (this.orderTrackerData?.orderItemStatusHistory) {
          this.mapOrderStatuses(this.orderTrackerData.orderItemStatusHistory);
          this.deliveredStatus =
            this.orderTrackerData?.orderItemStatusHistory.find(
              (status: { orderStatus: string }) =>
                status.orderStatus === 'Delivered'
            );
          this.deliveryDate = new Date(this.deliveredStatus.createdAt);
          this.returnStatus =
            this.orderTrackerData?.orderItemStatusHistory.find(
              (status: { orderStatus: string }) =>
                status.orderStatus === 'return requested'
            );
          if (this.returnStatus) {
            this.returnedDate = new Date(this.returnStatus.createdAt);
          }
          this.returnApprovedStatus =
            this.orderTrackerData?.orderItemStatusHistory.find(
              (status: { orderStatus: string }) =>
                status.orderStatus === 'return approved'
            );
          if (this.returnApprovedStatus) {
            this.returnApprovedDate = new Date(this.returnStatus.createdAt);
          }
          this.returnCompleteStatus =
            this.orderTrackerData?.orderItemStatusHistory.find(
              (status: { orderStatus: string }) =>
                status.orderStatus === 'Returned'
            );
          if (this.returnCompleteStatus) {
            this.returnCompleteDate = new Date(this.returnStatus.createdAt);
          }
          this.returnValidDate = new Date(this.deliveryDate);
          this.returnValidDate.setDate(this.deliveryDate.getDate() + 15);
        }
      },
      (error) => {
        console.log('Error fetching order tracker data:', error);
      }
    );
  }

  mapOrderStatuses(statusHistory: any[]) {
    const statusMapping: { [key: string]: string } = {
      ORDER_PLACED: 'Ordered',
      IN_PROGRESS: 'Ordered',
      PREPARE_TO_DISPATCH: 'Ordered',
      DISPATCHED: 'Shipped',
      OUT_FOR_DELIVERY: 'Out For Delivery',
      DELIVERED: 'Delivered',
    };
    const allSteps = ['Ordered', 'Shipped', 'Out For Delivery', 'Delivered'];
    const completedSteps = statusHistory
      .map((status) => statusMapping[status.orderStatus])
      .filter((step) => !!step);

    // Find the last completed step index
    const lastCompletedIndex = allSteps.findIndex(
      (step) => step === completedSteps[completedSteps.length - 1]
    );

    // Initialize steps with default statuses
    // this.statusSteps = allSteps.map((step) => {
    //   return {
    //     name: step,
    //     completed: completedSteps.includes(step),
    //     lastCompleted: completedSteps[completedSteps.length - 1] === step,
    //   };
    // });

    this.statusSteps = allSteps.map((step, index) => ({
      name: step,
      completed: index <= lastCompletedIndex,
      lastCompleted: index === lastCompletedIndex,
    }));

    console.log(this.statusSteps);
  }

  cancelOrderItem(orderIdentifier: string) {
    if (confirm('Are you sure to cancel the order?')) {
      this.orderService.cancelOrder(orderIdentifier).subscribe(
        (response) => {
          console.log('Order cancelled successfully:', response);
          // Optionally, update UI or inform user of successful cancellation
          alert('Your order has been cancelled.');
          this.getOrderTracker(orderIdentifier); // Refresh the order tracker after cancellation
        },
        (error) => {
          console.error('Error cancelling order item:', error);
          alert('There was an error canceling the order. Please try again.');
        }
      );
    }
  }

  returnOrder(orderIdentifier: string) {
    if (confirm('Are you sure to return the order?')) {
      this.orderService.returnOrder(orderIdentifier).subscribe(
        (response) => {
          // console.log('Order cancelled successfully:', response);
          // Optionally, update UI or inform user of successful cancellation
          alert('Return Request has been placed successfully');
          this.getOrderTracker(orderIdentifier); // Refresh the order tracker after cancellation
        },
        (error) => {
          console.error('Error returning order item:', error);
          alert('There was an error canceling the order. Please try again.');
        }
      );
    }
  }

  isDelivered(statusHistory: any[]): boolean {
    return statusHistory.some((status) => status.orderStatus === 'DELIVERED');
  }

  isCancelled(statusHistory: any[]): boolean {
    return statusHistory.some((status) => status.orderStatus === 'CANCELLED');
  }

  isOutForDelivery(statusHistory: any[]): boolean {
    return statusHistory.some(
      (status) => status.orderStatus === 'OUT_FOR_DELIVERY'
    );
  }

  isReturned(statusHistory: any[]): boolean {
    return statusHistory.some(
      (status) => status.orderStatus === 'RETURN_REQUESTED'
    );
  }

  isClosed(statusHistory: any[]): boolean {
    return statusHistory.some((status) => status.orderStatus === 'DELIVERED');
  }

  returnOrderItem(identifier: string) {
    // Implement your logic for handling return order here.
    this.returnOrder(this.identifier);
    console.log(`Return request for order: ${this.identifier}`);
  }
  isReturnable() {
    let returnable: boolean = false;
    this.deliveredStatus = this.orderTrackerData?.orderItemStatusHistory.find(
      (status: { orderStatus: string }) => status.orderStatus === 'DELIVERED'
    );
    // console.log(deliveredStatus);
    // Get the createdAt date if the status is found

    if (this.deliveredStatus) {
      const deliveredDate = new Date(this.deliveredStatus.createdAt);
      // console.log(deliveredDate);
      const currentDate = new Date(); // Current date
      const diffInTime = currentDate.getTime() - deliveredDate.getTime(); // Difference in milliseconds
      const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
      if (diffInDays <= 15) {
        returnable = true;
      }
    }
    return returnable;
  }
}
