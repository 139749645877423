<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  <!-- {{ header }} -->
  Received Orders
</div>

<div class="px-5 mx-lg-5 mx-0 mt-5">
  <div class="">
    <div
      class="d-flex justify-content-lg-between justify-content-center align-items-center flex-lg-row flex-column"
    >
      <div class="order-tabs col-lg-7 col-12">
        <button
          class="tab-button"
          (click)="onStatusChange('live')"
          [ngClass]="{ active: currentStatus === 'live' }"
        >
          Live
        </button>
        <button
          class="tab-button"
          (click)="onStatusChange('cancelled')"
          [ngClass]="{ active: currentStatus === 'cancelled' }"
        >
          Cancelled
        </button>
        <button
          class="tab-button"
          (click)="onStatusChange('closed')"
          [ngClass]="{ active: currentStatus === 'closed' }"
        >
          Closed
        </button>
      </div>
      <div class="col-lg-5 col-12">
        <div class="d-flex flex-lg-row flex-column">
          <div class="col-lg-7 col-12">
            <div
              class="col-lg-12 col-12 my-lg-0 my-3 rounded12 d-flex search-container"
            >
              <img src="../../assets/leftsearch.png" class="search-icon" />
              <input
                type="text"
                placeholder="Search All Orders"
                name="search"
                class="ms-2 col-11 rounded12 search-bg search-input"
                [(ngModel)]="searchTerm"
                (ngModelChange)="filterOrders()"
              />
            </div>
          </div>
          <div
            class="col-lg-5 col-12 d-lg-flex d-none align-items-center ps-lg-4 ps-2 justify-content-end"
          >
            <button
              class="bgorange text-white search-button"
              (click)="filterOrders()"
            >
              Search Orders
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="order-container">
      <div
        class="rounded4 bg-light-grey-1 order-header mb-2"
        (click)="toggleOrderDetails(order)"
        *ngFor="let order of allSellerOrder"
      >
        <div class="d-flex align-items-center px-lg-5 px-3 py-3" role="button">
          <div class="col-2">
            <span class="font16 fw400 font-primary initcap">
              <span class="font-black">Status</span>
              <p class="mb-0">{{ order?.orderStatus }}</p>
            </span>
          </div>
          <div class="col-3">
            <span class="mb-0 font16 fw400 font-black">
              Order Date: {{ order?.orderReceivedAt | date : "d MMMM yyyy" }}
            </span>
          </div>
          <div class="col-2">
            <span class="mb-0 font16 fw400 font-black">
              Total Quantity : {{ order?.quantity }}
            </span>
          </div>
          <div class="col-2">
            <span class="mb-0 font16 fw400 font-black">
              Total Price :
              <span class="font20"
                >₹{{ order?.totalPrice | number : "1.2-2" }}</span
              >
            </span>
          </div>
          <div class="col-3 text-end">
            <span class="font-black font16 fw400"
              >Order# {{ order.orderIdentifier }}</span
            >
          </div>
        </div>
        <div class="order-item bg-white" *ngIf="order?.showDetails">
          <div *ngFor="let item of order?.relatedOrderItem; let i = index">
            <div class="item-card">
              <div
                class="d-flex justify-content-between px-lg-5 px-3 pt-2 align-items-center"
              >
                <div>
                  <span *ngIf="item?.arrivalDate"
                    >Arriving on:
                    <p class="mb-0 font-primary">
                      {{ item.arrivalDate | date : "MMM-dd-yyyy" }}
                    </p>
                  </span>
                </div>
                <div>
                  Order Status:
                  <span class="font-primary">{{ item.orderItemStatus }}</span>
                </div>
                <div>
                  <span
                    class="font-black font16 fw400 hovertorange"
                    role="button"
                    (click)="
                      goToProductDetails(item?.productId, item?.identifier)
                    "
                    >Write A Product Review</span
                  >
                </div>
              </div>
              <div class="d-flex px-lg-5 pb-2 align-items-center">
                <div class="col-2">
                  <img
                    *ngIf="item?.image && item.image[0]?.imagePublicUrl"
                    [src]="item.image[0].imagePublicUrl"
                    alt="Product Image"
                    class="img-fluid"
                    width="100"
                  />
                </div>
                <div class="col-3">
                  <span class="font-black font16">
                    {{ item?.productHeading }}
                  </span>
                </div>
                <div class="col-2">Quantity: {{ item?.quantity }}</div>
                <div class="col-2">
                  Unit Price: ₹{{ item?.unitPrice | number : "1.2-2" }}
                  <p>Price: ₹{{ item?.price | number : "1.2-2" }}</p>
                </div>
                <div class="col-3 d-flex justify-content-end">
                  <div class="ratings">
                    <div>
                      <i
                        class="fa fa-star"
                        *ngFor="
                          let star of [].constructor(
                            item?.ratings?.overallRating
                          )
                        "
                      ></i>
                      <i
                        class="fa fa-star-o"
                        *ngFor="
                          let empty of [].constructor(
                            5 - item?.ratings?.overallRating
                          )
                        "
                      ></i>
                      <span>
                        {{ item?.ratings.overallRating | number : "1.1-1" }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-end py-2">
                      {{ item?.ratings.totalRating }} Ratings
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        *ngIf="allSellerOrder"
                        class="bgorange track-package text-white py-2"
                        (click)="navigateToOrderTracking(item.identifier)"
                      >
                        Track Package
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  order?.relatedOrderItem.length > 1 &&
                  i < order.relatedOrderItem.length - 1
                "
              >
                <hr class="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
