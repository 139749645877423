import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { UsersService } from '../../services/user.service';
import { AuthService } from '../auth-service.service';
import { CartService } from '../../services/cart.service';
import { ProductCardComponent } from '../product-card/product-card.component';
import { ProductDetailsDTO } from '../dto/ProductDetailsDTO';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ValidationErrors,
} from '@angular/forms';
import { ProductRatingComponent } from './product-rating/product-rating.component';
import { ProductQaComponent } from './product-qa/product-qa.component';
import { ProductRatingDetailsComponent } from './product-rating-details/product-rating-details.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { WishlistService } from '../../services/wishlist.service';
import { Meta, Title } from '@angular/platform-browser';
import { ToastService } from '../shared/toast.service';

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [
    CommonModule,
    ProductCardComponent,
    FormsModule,
    ProductRatingComponent,
    ProductQaComponent,
    ProductRatingDetailsComponent,
  ],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss',
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild('reviewModal') modal: any;
  @ViewChild('pinCodeModal') pinCodeModal: any;
  @ViewChild('wishListModal') wishModal: any;
  selectedRating: number = 0;
  feedback: any = null;
  fileForm: any;
  reviewHeader: any = null;
  prodQA: any;
  initialTechSpecs: number = 0;
  pinCode: any = 800001;
  showWishListModal: boolean = false;
  wishListModalMessage: any;
  quantity: any = 1;
  getFloor(arg0: any) {
    return Math.floor(arg0);
  }
  toggleWishlist(product: any): void {
    // this.userDTO = data;
    this.wishlistService
      .addWishlist(product.productId, this.user.id, product.listingId)
      .subscribe((data) => {
        if (this.wishListed) {
          this.wishListModalMessage =
            'Product has been removed from your wishlist!';
        } else {
          this.wishListModalMessage =
            'Product has been added to your wishlist!';
        }
        this.wishListed = !this.wishListed;
        this.showWishListModal = true;
        setTimeout(() => {
          this.showWishListModal = false;
        }, 2000);
      });
  }
  getWishListProucts() {
    this.wishlistService.getWishlist().subscribe((data) => {
      this.wishListedProducts = data;
      for (let i = 0; i < this.wishListedProducts.length; i++) {
        if (
          Number(this.productId) ===
            this.wishListedProducts[i].productDetailDto.productId &&
          this.product.listingId === this.wishListedProducts[i].listingId
        ) {
          this.wishListed = true;
          return;
        }
      }
    });
  }
  wishListedProducts: any[] = [];
  wishListed: boolean = false;
  wishlistIcon = '/assets/fav.png'; // Default icon
  product: any;
  mainImageUrl: any;
  currentStartIndex: number = 0;
  user: any;
  isAuth: boolean = false;
  deliveryDate: String | undefined;
  isSeller: any;
  cart: any;
  starsArray = [1, 2, 3, 4, 5];
  productId: any;
  listingId: any;
  cartQuantity: any = 0;
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private userService: UsersService,
    private authService: AuthService,
    private router: Router,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private localStorage: LocalStorageService,
    private wishlistService: WishlistService,
    private meta: Meta,
    private title: Title,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute
  ) {
    this.fileForm = this.fb.group({
      fileUpload: [[], [this.fileUploadValidator]],
    });
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.isAuth = this.authService.isAuthenticated();
      this.productId = params.get('productid');
      this.listingId = params.get('listingId');
      this.selectedRating = 0;
      this.initialTechSpecs = 4;
      this.getProductDetails(this.productId, this.listingId);
      if (isPlatformBrowser(this.platformId)) {
        // window.scrollTo(0, 0);
      }

      this.getWishListProucts();
      if (this.localStorage.getItem('pinCode')) {
        this.pinCode = this.localStorage.getItem('pinCode');
      }
      this.getProductNumberInCart();
    });
    this.isAuth = this.authService.isAuthenticated();
    if (this.isAuth) {
      this.getWishListProucts();
      this.userService.getUser().subscribe((data) => {
        this.user = data;
        if (!this.localStorage.getItem('pinCode')) {
          this.localStorage.setItem('pinCode', this.user.address.pinCode);
        }
      });
    }

    // this.deliveryDate = this.formatDate();
  }
  updateMetaTags(
    productTitle: string,
    productDescription: string,
    productImage: string
  ) {
    // Set the document title
    this.title.setTitle(productTitle);
    // Use updateTag to ensure tags are updated or added as needed
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    // this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({ property: 'og:title', content: productTitle });
    this.meta.updateTag({
      property: 'og:description',
      content: productDescription,
    });
    this.meta.updateTag({ property: 'og:image', content: productImage });
    this.meta.updateTag({
      property: 'og:image:alt',
      content: 'Product image alt text',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'PowerBihar' });
    this.meta.updateTag({ property: 'og:locale', content: 'en_US' });
  }

  getProductNumberInCart() {
    this.cartService
      .getProductCount(this.productId, this.product.listingId)
      .subscribe((data) => {
        if (data) {
          this.cartQuantity = data;
          this.quantity = data;
        } else {
          this.cartQuantity = 0;
        }
      });
  }
  viewAllTechSpecs() {
    this.initialTechSpecs = -1;
  }
  getProductDetails(id: any, listingId: any) {
    this.route.data.subscribe((data) => {
      this.product = data['allProducts'];
      if (this.product.productImageList) {
        this.mainImageUrl = this.product.productImageList[0].imagePublicUrl;
      }
      this.deliveryDate = this.formatDate(this.product.deliveryTime);
      this.getProdQA();
      this.quantity = this.product.lotSize;
      this.updateMetaTags(
        this.product.productHeading,
        this.product.productDescription,
        this.mainImageUrl
      );
      const queryParams = {
        name: this.product.productHeading.replace(/ /g, '_'),
        price: this.product.finalAmtWithTax,
      };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // 'merge' retains existing query params
        replaceUrl: true, // Prevent adding a new history entry
      });
      if (this.isAuth) {
        this.getWishListProucts();
      }
    });
  }
  refreshProductDetails(id: any, listingId: any) {
    this.productService.getProductById(id, listingId).subscribe((data) => {
      this.product = data;
      if (this.product.productImageList) {
        this.mainImageUrl = this.product.productImageList[0].imagePublicUrl;
      }
      this.deliveryDate = this.formatDate(this.product.deliveryTime);
      this.getProdQA();
      this.updateMetaTags(
        this.product.productHeading,
        this.product.productDescription,
        this.mainImageUrl
      );
    });
  }
  selectImage(index: number) {
    this.mainImageUrl = this.product.productImageList[index].imagePublicUrl;
  }
  nextImage() {
    if (this.currentStartIndex + 4 < this.product.productImageList.length) {
      this.currentStartIndex += 1;
    }
  }

  // Function to handle "previous" button click
  prevImage() {
    if (this.currentStartIndex > 0) {
      this.currentStartIndex -= 1;
    }
  }
  addBusinessDays(date: Date, daysToAdd: number): Date {
    let businessDaysAdded = 0;
    let newDate = new Date(date); // Create a copy of the input date

    while (businessDaysAdded < daysToAdd) {
      newDate.setDate(newDate.getDate() + 1); // Move to the next day

      // Check if it's not Saturday (6) or Sunday (0)
      if (newDate.getDay() !== 6 && newDate.getDay() !== 0) {
        businessDaysAdded++;
      }
    }

    return newDate;
  }
  formatDate(numberOfDays: any) {
    const today = new Date();

    // Add 7 days
    const deliveryDate = new Date(today);
    deliveryDate.setDate(this.addBusinessDays(today, numberOfDays).getDate());
    return deliveryDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
  onQuantityChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    this.quantity = value; // Convert string to number
    console.log('Selected quantity:', this.quantity);
  }
  addToCart(product: any, quantity: any, buyNow: boolean) {
    if (!this.isAuth) {
      this.router.navigate(['/signin']);
      return;
    }
    this.isSeller = this.user.isSeller || false;

    if (!this.hasCompleteUserDetails()) {
      this.showProfileIncompleteModal();
      return;
    }

    const addProductToCartDto = {
      price: product.finalAmtWithTax,
      product_color_id: '1',
      product_id: product.productId,
      quantity: this.quantity,
      sellerId: product.sellerId,
      tax: product.taxAmount * this.quantity,
      mrp: product.mrp,
      listingId: this.listingId,
      pinCode: this.pinCode,
    };

    if (product.isAvailableAtShippingAddress) {
      this.cartService.addCart(addProductToCartDto).subscribe(
        (data) => {
          this.cart = data;
          if (!buyNow) {
            // alert('Product added to cart successfully.');
            this.toastService.showToast(
              'Product added to cart successfully!',
              'bg-success'
            );
            this.getProductNumberInCart();
          }
          this.updateCartItemCount();
          if (buyNow) {
            this.router.navigate(['/cart']);
          }
        },
        (error) => {
          console.error('Error adding product to cart:', error);
          this.toastService.showToast(
            'Failed to add product to cart.',
            'bg-danger'
          );
        }
      );
    } else {
      // alert('Item not available at Default Shipping Address');
      this.toastService.showToast(
        'Item not available at Default Shipping Address.',
        'bg-warning'
      );
    }
  }
  navigateToProductDetails(id: string, listingId: string) {
    this.router.navigate([`/product/${id}/${listingId}`]);
  }
  updateCartItemCount(): void {
    this.cartService.getCartList().subscribe((cartList) => {
      // Calculate total items count in the cart
      const totalCount = cartList.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      );
      // Update cart item count in the service
      this.cartService.updateCartItemCount(totalCount);
    });
  }
  hasCompleteUserDetails(): boolean {
    const { firstName, lastName, phoneNumber, emailAddress } = this.user;
    return phoneNumber;
  }

  showProfileIncompleteModal() {
    const modal = document.getElementById('profileIncompleteModal');
    if (modal) {
      const modalInstance = new (window as any).bootstrap.Modal(modal);
      modalInstance.show();
    }
  }

  reviewSubmit() {
    this.modalService.open(this.modal);
  }
  selectRating(rating: number) {
    this.selectedRating = rating;
    // this.submitRating();
  }
  submitRating() {
    // console.log(this.feedback);
    let filesArray: File[] = [];
    Object.keys(this.fileForm.controls).forEach((controlName) => {
      const control = this.fileForm.get(controlName);
      if (control) {
        if (controlName === 'fileUpload' && control.value) {
          const files = control.value;
          for (let i = 0; i < files.length; i++) {
            filesArray.push(files[i]);
          }
          // filesArray = files;
        }
      }
    });

    if (filesArray.length > 0) {
      this.productService
        .uploadReviewImages(this.product.productId, filesArray)
        .subscribe((data) => {
          this.productService
            .rateProduct(
              this.product.productId,
              this.selectedRating,
              this.feedback,
              data.id,
              this.reviewHeader
            )
            .subscribe((data) => {
              this.selectedRating = 0;
              this.feedback = null;
              this.reviewHeader = null;
              this.fileForm.reset();
              this.modalService.dismissAll();
            });
        });
    } else {
      this.productService
        .rateProduct(
          this.product.productId,
          this.selectedRating,
          this.feedback,
          null,
          this.reviewHeader
        )
        .subscribe((data) => {
          this.selectedRating = 0;
          this.feedback = null;
          this.reviewHeader = null;
          this.modalService.dismissAll();
        });
    }
  }
  addFiles(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const newFiles = inputElement.files;

    if (newFiles && newFiles.length > 0) {
      // Merge the new files with the existing files
      const existingFiles = this.fileForm.get('fileUpload').value || [];
      const combinedFiles = [...existingFiles, ...Array.from(newFiles)];
      this.fileForm.get('fileUpload').setValue(combinedFiles);
      // console.log('Current Files:', this.fileForm.get('fileUpload').value);
    }
  }
  fileUploadValidator(control: AbstractControl): ValidationErrors | null {
    const allowedExtensions = ['webp', 'jpeg', 'jpg', 'png'];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    const maxTotalFiles = 5; // Maximum total number of files allowed

    if (control.value) {
      const files = control.value;

      // Check total number of files
      if (files.length > maxTotalFiles) {
        return { totalFilesExceeded: true };
      }

      for (const file of files) {
        // Check file extension
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          return { invalidExtension: true };
        }

        // Check file size
        if (file.size > maxSize) {
          return { fileSizeExceeded: true };
        }
      }
    }

    return null;
  }
  getErrorMessage(): string {
    const errors = this.fileForm.get('fileUpload').errors;

    if (errors?.totalFilesExceeded) {
      return 'The total number of files has been exceeded. The maximum is 5.   ';
    }
    if (errors?.invalidExtension) {
      return 'Invalid file type. Allowed file extensions are .webp, .jpeg, .jpg and .png.      ';
    }
    if (errors?.fileSizeExceeded) {
      return 'The file size has been exceeded. The maximum is 5 MB.   ';
    }
    return '';
  }
  removeFile(index: number): void {
    const files = this.fileForm.get('fileUpload').value;
    files.splice(index, 1);
    this.fileForm.get('fileUpload').setValue(files);
  }
  getProdQA() {
    this.productService.getQA(this.product.productId).subscribe((data) => {
      this.prodQA = data;
    });
  }
  openPinCodeModal() {
    this.modalService.open(this.pinCodeModal);
  }
  setPinCode() {
    this.localStorage.setItem('pinCode', this.pinCode);
    this.refreshProductDetails(this.productId, this.listingId);
    this.modalService.dismissAll();
  }
  buyNow(product: any) {
    this.addToCart(product, this.quantity, true);
  }

  preventNegativeInput(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }
  getLotSizeSteps(lotSize: number): number[] {
    const steps = [];
    for (let i = lotSize; i <= lotSize * 5; i += lotSize) {
      steps.push(i);
    }
    return steps;
  }
}
