<div
  class="grey-bg font24 fw400 py-4 text-white d-flex justify-content-center bgimage"
>
  {{ product.category }}
</div>
<div
  class="d-flex col-12 justify-content-lg-evenly justify-content-center align-items-lg-start align-items-center mt-5 flex-lg-row flex-column"
>
  <div class="d-flex col-lg-4 col-10 justify-content-end flex-column">
    <div class="py-4 border-grey d-flex flex-column px-5 bg-light-grey-1">
      <div class="align-items-center d-flex justify-content-center">
        <img
          src="{{ mainImageUrl }}"
          alt=""
          width="450px"
          height="400px"
          class="d-lg-block d-none"
          style="object-fit: contain"
        />
        <img
          src="{{ mainImageUrl }}"
          alt=""
          width="250px"
          height="200px"
          class="d-lg-none d-block"
          style="object-fit: contain"
        />
      </div>
      <div
        class="mt-3 d-flex flex-row align-items-center justify-content-center"
      >
        <!-- Left Arrow -->
        <button
          (click)="prevImage()"
          [disabled]="currentStartIndex === 0"
          class="transparent-button"
        >
          &lt;
          <!-- Left arrow icon -->
        </button>

        <!-- Images Display -->
        <div
          class="d-flex flex-row gap-3 mx-3"
          *ngIf="product.productImageList"
        >
          <div
            *ngFor="
              let image of product.productImageList.slice(
                currentStartIndex,
                currentStartIndex + 4
              );
              let i = index
            "
          >
            <div
              role="button"
              (click)="selectImage(i + currentStartIndex)"
              class="border-black rounded-2"
            >
              <img
                src="{{ image.imagePublicUrl }}"
                alt=""
                width="80px"
                height="80px"
                class="rounded-2 d-lg-block d-none"
                style="object-fit: contain"
              />
              <img
                src="{{ image.imagePublicUrl }}"
                alt=""
                width="40px"
                height="40px"
                class="rounded-2 d-block d-lg-none"
                style="object-fit: contain"
              />
            </div>
          </div>
        </div>

        <!-- Right Arrow -->
        <button
          (click)="nextImage()"
          [disabled]="currentStartIndex + 4 >= product.productImageList.length"
          class="transparent-button"
          *ngIf="product.productImageList"
        >
          &gt;
          <!-- Right arrow icon -->
        </button>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-self-end my-4 gap-lg-5 gap-2 font22 fw400 rounded4 col-12 flex-lg-row flex-column-reverse"
    >
      <button
        class="buyNowButton px-lg-2 px-0 py-1 py-lg-0 col-lg-3 col-12"
        (click)="buyNow(product)"
      >
        Buy Now
      </button>
      <div class="d-flex flex-column">
        <div
          class="d-flex flex-row gap-2 align-items-center justify-content-lg-end justify-content-between"
        >
          <!-- <label for="quantity"> Qty</label> -->
          <input
            type="number"
            [(ngModel)]="quantity"
            class="text-center ps-1 font18 lh32 rounded-2 col-3"
            (keypress)="preventNegativeInput($event)"
            [disabled]="product.lotSize > 1"
          />
          <!-- <select
            [(ngModel)]="quantity"
            class="form-select text-center ps-1 font18 rounded-2 col-3"
            *ngIf="product.lotSize > 1"
            (change)="onQuantityChange($event)"
          >
            <option
              *ngFor="let step of getLotSizeSteps(product.lotSize)"
              [value]="step"
            >
              {{ step }}
            </option>
          </select> -->
          <button
            class="cartButton px-4 py-1 py-lg-0 col-lg-5 col-8"
            (click)="addToCart(product, quantity, false)"
            style="min-width: fit-content"
          >
            Add To Cart
          </button>
        </div>
        <div
          *ngIf="cartQuantity > 0"
          class="font14 font-primary text-lg-end text-start"
        >
          ({{ cartQuantity }} in cart)
        </div>
      </div>
    </div>
    <app-product-rating
      [product]="product"
      class="d-lg-block d-none"
    ></app-product-rating>
  </div>
  <div
    class="d-flex col-lg-6 col-11 justify-content-lg-start justify-content-center flex-column"
  >
    <div
      class="font24 fw400 lh35 text-black d-flex justify-content-center justify-content-lg-start flex-column text-center text-lg-start"
    >
      {{ product.productHeading }} | SKU Code: {{ product.itemId }}
      <div *ngIf="product.brandInfo">
        <img
          src="{{ product.brandInfo.brandLogoUrl }}"
          alt="{{ product.brandInfo.brandName }}"
          width="80px"
        />
      </div>
      <div class="d-flex text-center text-lg-start font11 fw400 flex-column">
        <div class="mb-2 me-3">
          Sold By
          <span
            class="search-bg p-2 font12 ms-3"
            style="max-width: fit-content"
            >{{ product.sellerName }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="my-3 justify-content-evenly align-items-center justify-content-lg-between d-flex"
      *ngIf="product.avgRatings"
    >
      <div class="rating-stars">
        <div
          class="star"
          *ngFor="let star of starsArray; let i = index"
          [ngClass]="{
            filled: i < getFloor(product.avgRatings.overallRating),
            'half-filled':
              i >= getFloor(product.avgRatings.overallRating) &&
              i < product.avgRatings.overallRating
          }"
        >
          ★
        </div>
      </div>
      <div>{{ product.avgRatings.totalRating }} Ratings</div>
      <div
        class=""
        (click)="toggleWishlist(product)"
        role="button"
        style="min-width: 10px; min-height: 20px"
        *ngIf="isAuth"
      >
        <img src="../../assets/fav.png" alt="Wishlist" *ngIf="!wishListed" />
        <img src="../../assets/red-fav.png" alt="Wishlist" *ngIf="wishListed" />
      </div>
    </div>
    <div
      class="d-flex justify-content-lg-start justify-content-center flex-lg-row flex-row gap-4"
    >
      <div
        class="bg-light-grey-1 d-flex flex-column border-grey justify-content-lg-start justify-content-center align-items-lg-start align-items-center p-4 rounded-4"
        style="max-width: fit-content"
      >
        <div class="d-flex flex-row font24 fw400 gap-1">
          <div class="font-primary" *ngIf="product.discountAmount > 0">
            -{{ product.discountAmount / product.mrp | percent : "1.0-0" }}
          </div>
          <div>₹{{ product.finalAmtWithTax | number : "1.2-2" }}</div>
        </div>
        <div class="text-light-grey font14 lh35 fw400">
          MRP: ₹{{ product.mrp | number : "1.2-2" }} <br />
          <div class="font12">Inclusive of all taxes</div>
          <div *ngIf="product.lotSize > 1" class="font13 font-primary">
            * Sold in Pack of {{ product.lotSize }}
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column font14 gap-4 border-grey justify-content-center rounded-4 p-3 bg-light-grey-1 align-items-center"
        role="button"
        (click)="openPinCodeModal()"
        style="max-width: fit-content"
      >
        <div class="d-flex flex-column gap-3 align-items-center">
          <div class="fw400">
            Deliver To
            <span class="font-primary fw500"
              >{{ pinCode }}
              <img src="../../assets/edit.png" class="img-fluid ms-3"
            /></span>
          </div>
          <div class="font-primary fw500">
            <span>
              <span class="bgorange text-white font12 px-4 py-2">
                Check at Pincode</span
              ></span
            >
          </div>
          <!-- <div class="font-primary fw500" *ngIf="!user">800001</div> -->
        </div>
        <div class="d-flex flex-column align-items-center gap-1">
          <div class="font-primary fw500">
            <span *ngIf="product.isAvailableAtShippingAddress">In Stock</span>
            <span
              *ngIf="!product.isAvailableAtShippingAddress"
              class="text-danger"
              >Not Deliverable at this Pincode</span
            >
          </div>
          <div class="fw400" *ngIf="product.isAvailableAtShippingAddress">
            Delivery by
            <span class="font-primary fw500">{{ deliveryDate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <hr />
    </div>
    <div
      *ngIf="product.relatedProductList.length > 0"
      class="font16 fw500 my-2"
    >
      Other Size and Color Options
    </div>
    <div
      *ngIf="product.relatedProductList.length > 0"
      class="d-flex flex-wrap gap-4"
    >
      <div
        *ngFor="let rel of product.relatedProductList; let i = index"
        class="col-3"
      >
        <div
          *ngIf="i <= 2"
          class="bg-light-grey-1 border-grey rounded-2 d-flex align-items-center flex-column justify-content-center"
          role="button"
          (click)="navigateToProductDetails(rel.productId, rel.listingId)"
        >
          <img
            src="{{ rel.productImageList[0].imagePublicUrl }}"
            alt=""
            width="80px"
            height="80px"
            class="rounded-2 d-lg-block m-2"
            style="object-fit: contain"
          />
          <div
            class="px-lg-3 px-1 py-1"
            style="max-width: 100%; white-space: nowrap; overflow: hidden"
            [attr.title]="rel.productHeading"
          >
            {{ rel.productHeading }}
          </div>
          <div class="pb-2">
            <span class="d-lg-block d-none">Offer Price:</span>
            <span class="font-primary fw600">
              ₹{{ rel.finalAmtWithTax | number : "1.2-2" }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="font14 fw400 my-lg-4 my-3 mx-0 mx-lg-0 text-center text-lg-start d-flex flex-column justify-content-center justify-content-lg-start"
    >
      Product Description
      <div
        class="border-grey p-4 mt-2 bg-light-grey-1 text-center text-lg-start d-flex flex-column justify-content-center justify-content-lg-start"
      >
        {{ product.productDescription }}
      </div>
    </div>
    <div
      class="font14 fw400 my-lg-4 my-2 ms-0 ms-lg-0 text-lg-start text-center"
      *ngIf="product.productTechSpecs"
    >
      Product Specification
      <div class="py-1 mt-2">
        <!-- <div *ngFor="let high of product.productHighlights" class="pb-1">
          <li>
            {{ high.highlightText }}
          </li>
        </div> -->
        <div
          *ngFor="let tech of product.productTechSpecs; let i = index"
          class="text-start"
        >
          <div
            *ngIf="i <= initialTechSpecs || initialTechSpecs === -1"
            class="col-lg-8 col-12 d-flex border-bottom-black justify-content-between"
            [ngClass]="{
              'bg-light-grey-1': i % 2 === 0,
              'border-top-black': i == 0
            }"
          >
            <span
              class="ps-2 py-3 col-4 border-right-black align-items-center d-flex justify-content-start"
            >
              {{ tech.techSpecHeader }}</span
            >
            <span
              class="fw500 col-6 py-3 align-items-center d-flex justify-content-start"
              >{{ tech.techSpecDetails }}</span
            >
            <!-- <li class="ms-0 ms-lg-4 mt-1 d-lg-flex d-none">
              {{ tech.techSpecDetails }}
            </li> -->
          </div>
        </div>
        <div
          *ngIf="initialTechSpecs === 4 && product.productTechSpecs.length > 5"
          class="font-primary font14 fw400 mt-2"
          role="button"
          (click)="initialTechSpecs = -1"
        >
          See Full Specification
        </div>
        <div
          *ngIf="initialTechSpecs === -1 && product.productTechSpecs.length > 5"
          class="font-primary font14 fw400 mt-2"
          role="button"
          (click)="initialTechSpecs = 4"
        >
          See Less
        </div>
      </div>
    </div>
    <div>
      <!-- {{ product | json }} -->
      <div class="font14"><b>Country Origin:</b> {{ product.origin }}</div>
      <div class="mt-2 font14">
        <b>Manufactured By:</b> {{ product.manufacturedBy }}
      </div>
    </div>
    <div
      *ngIf="isAuth"
      class="d-flex justify-content-lg-start justify-content-center mt-2"
    >
      <button class="buyNowButton px-5 py-3 font16" (click)="reviewSubmit()">
        Rate This Product
      </button>
    </div>
  </div>
</div>
<app-product-rating
  [product]="product"
  class="d-lg-none d-block mt-2"
></app-product-rating>
<app-product-rating-details
  [ratings]="product.ratings"
  *ngIf="product.ratings"
></app-product-rating-details>
<app-product-qa [prodQA]="prodQA" *ngIf="prodQA"></app-product-qa>
<div
  class="d-flex justify-content-center col-12 flex-column align-items-center my-5"
>
  <div *ngIf="product.category">
    <app-product-card
      [productcat]="product.category"
      [user]="user"
      [isAuth]="isAuth"
      [wishListedProducts]="wishListedProducts"
      [pinCode]="pinCode"
    ></app-product-card>
  </div>
</div>
<ng-template
  class="modal fade mt-5 center"
  #reviewModal
  tabindex="-1"
  aria-label="Review"
  aria-hidden="true"
>
  <div class="modal-header text-center">Add Review</div>
  <div class="modal-body text-center">
    <div class="rating-stars justify-content-center gap-3 mt-4" role="button">
      <span
        class="star"
        *ngFor="let star of starsArray; let i = index"
        [ngClass]="{ filled: i < selectedRating }"
        (click)="selectRating(i + 1)"
        style="font-size: 30px !important"
      >
        ★
      </span>
    </div>
    <div class="d-flex col-12 flex-column mt-4">
      <input
        type="text"
        name="Header"
        [(ngModel)]="reviewHeader"
        placeholder="Review Header"
        class="font12"
      />
      <textarea
        [(ngModel)]="feedback"
        placeholder="Kindly provide your review"
        rows="4"
        class="w-100 mt-4 font12"
      ></textarea>
    </div>
    <div class="my-4 d-flex justify-content-start align-items-center">
      <label
        for="fileInput"
        class="cartButton py-2 px-3 mt-4 fw400 cursor-pointer font14 lh25"
        role="button"
        [ngClass]="{ disable: fileForm.get('fileUpload').value.length >= 5 }"
        >Upload Images</label
      >
      <label class="fw400 font12 mt-2 mt-lg-0 pt-4 ms-3" for="fileUpload"
        >( .jpeg | .jpg | .png | .webp )</label
      >
      <input
        type="file"
        id="fileInput"
        class="d-none"
        role="button"
        (change)="addFiles($event)"
        multiple
        [disabled]="fileForm.get('fileUpload').value.length >= 5"
      />
    </div>
    <div
      *ngFor="let file of fileForm.get('fileUpload').value; let i = index"
      class="d-flex justify-content-start mb-2 flex-row"
    >
      <!-- {{ fileForm.get("fileUpload").value.length }} -->
      <div data-bs-theme="dark">
        <span>{{ file.name }}</span>
        <button
          type="button"
          class="bg-transparent text-danger bordernone"
          aria-label="Close"
          (click)="removeFile(i)"
        >
          X
        </button>
      </div>
    </div>
    <div *ngIf="fileForm.get('fileUpload').hasError('totalFilesExceeded')">
      <p class="text-danger font12">{{ getErrorMessage() }}</p>
    </div>
    <div *ngIf="fileForm.get('fileUpload').hasError('invalidExtension')">
      <p class="text-danger font12">{{ getErrorMessage() }}</p>
    </div>
    <div *ngIf="fileForm.get('fileUpload').hasError('fileSizeExceeded')">
      <p class="text-danger font12">{{ getErrorMessage() }}</p>
    </div>
  </div>

  <div class="justify-content-center align-items-center d-flex mb-4">
    <button
      class="buyNowButton col-3 font16"
      [disabled]="selectedRating <= 0"
      (click)="submitRating()"
    >
      Submit
    </button>
  </div>
  <!-- <div class="modal-body">
</div> -->
</ng-template>
<ng-template
  class="modal fade mt-5 center"
  #pinCodeModal
  tabindex="-1"
  aria-label="Review"
  aria-hidden="true"
>
  <div
    class="d-flex gap-4 p-4 flex-column justify-content-center h-25 align-items-center"
  >
    <div
      class="d-flex flex-column justify-content-center align-items-center gap-3"
    >
      <label for="pinCode">Delivery PinCode</label>
      <input
        type="text"
        name="pinCode"
        [(ngModel)]="pinCode"
        class="text-center"
        maxlength="6"
        pattern="[0-9]*"
        placeholder="Enter Pin Code"
      />
    </div>
    <button
      class="cartButton"
      (click)="setPinCode()"
      [disabled]="pinCode.length < 6"
    >
      Save
    </button>
  </div>
</ng-template>

<div
  *ngIf="showWishListModal"
  style="
    position: fixed !important;
    right: 10px !important;
    top: 5%;
    z-index: 1000;
  "
>
  <span
    class="p-4 d-flex justify-content-center align-items-center bg-white bgorangeshadow rounded-2 font-primary"
    >{{ wishListModalMessage }}</span
  >
</div>
